import React from 'react';
import ArticleBusinessComponent from '../components/caseStudyComponent/articleBusinessComponent';
import ArticleHomeComponent from '../components/caseStudyComponent/articleHomeComponent';
import ImprovementsComponent from '../components/caseStudyComponent/improvementsBusinessComponent';
import Footer from '../components/footerComponent';
import Header from '../components/headerComponent';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
const ArticleHealthyCare = props => {
  const {
    data: {
      wpPage: {
        seo,
        articleHomeSection: {
          articleHomeTitle,
          articleHomeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: articleHomeImage },
            },
          },
        },
        articleBusinessSection: {
          articleBusinessTitle,
          articleBusinessSubtitle,
          articleBusinessDescription,
          articleBusinessText,
        },
        articleChallengeSection: {
          articleChallengeTitle,
          articleChallengeText,
          articleChallengeSubText,
          articleChallengeDescription,
          articleChallengeSubDescription,
          articleChallengeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: articleChallengeImage },
            },
          },
        },
        articleMotivation: {
          articleMotivationTitle,
          articleMotivationText,
          articleMotivationDescription,
        },
        articleImprovementsSection: {
          articleImprovementsTitle,
          articleImprovementsText,
          articleImprovementsTextCol,
          articleImprovementsSubText,
          articleImprovementsSubDescriptionCol,
          articleImprovementsSubDescription,
          articleImprovementsDescriptionHealthcare,
          articleImprovementsDescriptionCol,
          articleImprovementsDescription,
          articleImprovementsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: articleImprovementsImage },
            },
          },
        },
        articleOutcomeSection: {
          articleOutcomeTitle,
          articleOutcomeText,
          articleOutcomeDescription,
          articleOutcomeSubtext,
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
     <Layout title={title} seo={seo}>
       <Header/>
      <ArticleHomeComponent
        articleTitle={articleHomeTitle}
        articleBackgroundImage={articleHomeImage}
      />
      <ArticleBusinessComponent
        articleTitle={articleBusinessTitle}
        articleText={articleBusinessSubtitle}
        articleDescription={articleBusinessDescription}
        articleSubText={articleBusinessText}
      />
      <ImprovementsComponent
        improvementsTitle={articleChallengeTitle}
        improvementsDescriptionCol={articleChallengeDescription}
        improvementsSubDescriptionCol={articleChallengeSubDescription}
        improvementsText={articleChallengeText}
        improvementsSubText={articleChallengeSubText}
        ImprovementsImage={articleChallengeImage}
      />
      <ArticleBusinessComponent
        articleTitle={articleMotivationTitle}
        articleText={articleMotivationText}
        articleDescription={articleMotivationDescription}
      />
      <ImprovementsComponent
        improvementsTitle={articleImprovementsTitle}
        improvementsSubTitle={articleImprovementsText}
        improvementsDescriptionCol={articleImprovementsDescriptionCol}
        improvementsSubDescriptionCol={articleImprovementsSubDescriptionCol}
        improvementsTextCol={articleImprovementsTextCol}
        improvementsText={articleImprovementsDescription}
        improvementsSubText={articleImprovementsDescriptionHealthcare}
        improvementsDescription={articleImprovementsSubText}
        improvementsSubDescription={articleImprovementsSubDescription}
        ImprovementsImage={articleImprovementsImage}
      />
      <ArticleBusinessComponent
        articleTitle={articleOutcomeTitle}
        articleText={articleOutcomeText}
        articleDescription={articleOutcomeDescription}
        articleSubText={articleOutcomeSubtext}
      />
      <Footer/>
      </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "homehealthcare-usecase" }) {
      articleHomeSection {
        articleHomeTitle
        articleHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleBusinessSection {
        articleBusinessTitle
        articleBusinessSubtitle
        articleBusinessDescription
        articleBusinessText
      }
      articleChallengeSection {
        articleChallengeTitle
        articleChallengeText
        articleChallengeSubText
        articleChallengeDescription
        articleChallengeSubDescription
        articleChallengeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleMotivation {
        articleMotivationTitle
        articleMotivationText
        articleMotivationDescription
      }
      articleImprovementsSection {
        articleImprovementsTitle
        articleImprovementsText
        articleImprovementsTextCol
        articleImprovementsSubText
        articleImprovementsSubDescriptionCol
        articleImprovementsSubDescription
        articleImprovementsDescriptionHealthcare
        articleImprovementsDescriptionCol
        articleImprovementsDescription
        articleImprovementsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleOutcomeSection {
        articleOutcomeTitle
        articleOutcomeText
        articleOutcomeDescription
        articleOutcomeSubtext
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default ArticleHealthyCare;
